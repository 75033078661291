<template>
  <div class="form-container mb-4">
    <div>
      <validation-observer
        ref="rulesForm"
        class="form"
      >
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <b-row>
            <b-col
              class="mb-50 d-title"
              xl="1"
              lg="1"
              md="12"
              sm="12"
            />
            <b-col
              class="mb-50 d-title"
              xl="1"
              lg="1"
              md="12"
              sm="12"
            >数量</b-col>
            <b-col
              class="mb-50 d-title"
              xl="9"
              lg="9"
              md="12"
              sm="12"
            >
              <b-row>
                <b-col
                  class="mb-50 d-title"
                  xl="2"
                  lg="2"
                  md="6"
                  sm="12"
                >类型</b-col>
                <b-col
                  class="mb-50 d-title"
                  xl="2"
                  lg="2"
                  md="6"
                  sm="12"
                >长</b-col>
                <b-col
                  class="mb-50 d-title"
                  xl="2"
                  lg="2"
                  md="6"
                  sm="12"
                >宽</b-col>
                <b-col
                  class="mb-50 d-title"
                  xl="2"
                  lg="2"
                  md="6"
                  sm="12"
                >高</b-col>
                <b-col
                  class="mb-50 d-title"
                  xl="2"
                  lg="2"
                  md="6"
                  sm="12"
                >属性</b-col>
                <b-col
                  class="mb-50 d-title"
                  xl="2"
                  lg="2"
                  md="6"
                  sm="12"
                >重量</b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Row Loop -->
          <div
            v-for="(item, index) in items"
            :key="index"
            ref="row"
          >
            <b-row>
              <b-col
                xl="1"
                lg="1"
                md="12"
                sm="12"
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <span>#{{ index + 1 }}</span>
              </b-col>
              <b-col
                xl="1"
                lg="1"
                md="12"
                sm="12"
                class="d-flex flex-column justify-content-center"
              >
                <span>{{ item.qty }}</span>
              </b-col>

              <b-col
                xl="9"
                lg="9"
                md="12"
                sm="12"
              >
                <b-row>
                  <!-- 包裹类型 -->
                  <b-col
                    xl="2"
                    lg="2"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      labelFor="parcel-type"
                    >
                      <!-- rules="required" -->
                      <validation-provider
                        #default="{ errors }"
                        name="parcel-type"
                        rules="required"
                      >
                        <b-form-select
                          id="parcel-type"
                          v-model="item.parcel_type_id"
                          :options="typeList"
                          @change="handleTypeChange(item)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- 长 -->
                  <b-col
                    xl="2"
                    lg="2"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      labelFor="length"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="length"
                        rules="required"
                      >
                        <b-input-group
                          append="cm"
                          class="input-group-merge"
                        >
                          <b-form-input
                            id="length"
                            v-model="item.length"
                            type="number"
                            placeholder="10"
                          />
                        </b-input-group>
                        <span
                          v-if="item.length > 120"
                          class="max-error"
                        >最长边为120cm</span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- 宽 -->
                  <b-col
                    xl="2"
                    lg="2"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      labelFor="width"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="width"
                        rules="required"
                      >
                        <b-input-group
                          append="cm"
                          class="input-group-merge"
                        >
                          <b-form-input
                            id="width"
                            v-model="item.width"
                            type="number"
                            placeholder="10"
                          />
                        </b-input-group>
                        <span
                          v-if="item.width > 120"
                          class="max-error"
                        >最长边为120cm</span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- 高 -->
                  <b-col
                    xl="2"
                    lg="2"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      labelFor="height"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="height"
                        rules="required"
                      >
                        <b-input-group
                          append="cm"
                          class="input-group-merge"
                        >
                          <b-form-input
                            id="height"
                            v-model="item.height"
                            type="number"
                            placeholder="10"
                          />
                        </b-input-group>
                        <span
                          v-if="item.height > 120"
                          class="max-error"
                        >最长边为120cm</span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- 属性 -->
                  <b-col
                    xl="2"
                    lg="2"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      labelFor="parcel-tags"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="parcel-tags"
                        rules="required"
                      >
                        <b-form-select
                          id="parcel-tags"
                          v-model="item.parcel_tag_id"
                          :options="tagList"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- 重量 -->
                  <b-col
                    xl="2"
                    lg="2"
                    md="6"
                    sm="12"
                  >
                    <b-form-group
                      labelFor="weight"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="weight"
                        rules="required"
                      >
                        <b-input-group
                          append="kg"
                          class="input-group-merge"
                        >
                          <b-form-input
                            id="weight"
                            v-model="item.weight"
                            type="number"
                            placeholder="10"
                          />
                        </b-input-group>
                        <span
                          v-if="item.weight > 30"
                          class="max-error"
                        >最大重量为30kg</span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                xl="1"
                lg="1"
                md="12"
                sm="12"
                class="del-col"
              >
                <!-- Remove Button -->
                <feather-icon
                  class="mt-0 mt-md-2 del-icon"
                  style="cursor: pointer;margin-top: 8px !important;color:red;width:26px;height:26px;"
                  icon="XIcon"
                  @click="removeItem(index,item)"
                />
                <feather-icon
                  class="mt-0 mt-md-2 del-icon ml-1"
                  style="cursor: pointer;margin-top: 8px !important;color:#FFAF2A;width:20px;height:20px;"
                  icon="CopyIcon"
                  @click="copyItem(index,item)"
                />
              </b-col>
            </b-row>
            <hr>
          </div>

        </b-form>
      </validation-observer>
    </div>
    <div class="d-flex justify-content-between align-items-center  mt-2">
      <div class="d-title">
        包裹数量 {{ parcelTotal }}
      </div>
      <b-button
        v-if="country_id !== 196"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="relief-primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    tagList: {
      type: Array,
      default: () => [],
    },
    typeList: {
      type: Array,
      default: () => [],
    },
    parcels: {
      type: Array,
      default: () => [],
    },
    shipmentId: {
      type: [Number, String],
      default: () => '',
    },
    // eslint-disable-next-line
    country_id: {
      type: [Number, String],
      default: () => '',
    },
    // eslint-disable-next-line
    is_include_battery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      items: [],
      nextTodoId: 2,
      tags: [],
    }
  },
  computed: {
    parcelTotal({ items }) {
      return items.length
    },
  },
  watch: {
    parcels: {
      handler(val) {
        if (val.length) {
          this.items = val.map(item => ({
            ...item,
            parcel_id: item.parcel_id,
            qty: 1,
          }))
          this.initTrHeight()
        }
      },
      deep: true,
    },
    items: {
      handler() {
        this.initTrHeight()
        this.$emit('change')
      },
      deep: true,
    },
    is_include_battery: {
      handler(val) {
        if (val) {
          if (this.items.length) {
            this.items[0].parcel_tag_id = 8
          }
        } else {
          // eslint-disable-next-line
          if (this.items.length) {
            this.items[0].parcel_tag_id = undefined
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initTrHeight()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const obj = {
      parcel_id: -1,
      parcel_type_id: 0,
      parcel_tag_id: undefined,
      qty: 1,
      length: undefined,
      width: undefined,
      height: undefined,
      weight: undefined,
      store_id: this.userInfo.stores * 1,
    }
    this.items.push(obj)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    handleTypeChange(item) {
      this.typeList.forEach(v => {
        if (item.parcel_type_id === v.value) {
          // eslint-disable-next-line
          item.length = v.default_length
          // eslint-disable-next-line
          item.width = v.default_width
          // eslint-disable-next-line
          item.height = v.default_height
          // eslint-disable-next-line
          item.parcel_tag_id = v.tags[0]
        }
      })
    },
    validationForm() {
      return new Promise(resolve => {
        this.$refs.rulesForm.validate().then(success => {
          resolve(success)
        })
      })
    },
    repeateAgain() {
      if (this.country_id === 214) {
        this.$bvToast.toast('包裹邮寄台湾，一个预报运单对应一个包裹；如果有多个包裹，请做多张预报单。同名预报单会分批发货', {
          title: '提示',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
        return
      }
      this.items.push({
        parcel_id: -1,
        parcel_type_id: 0,
        parcel_tag_id: this.is_include_battery ? 8 : undefined,
        qty: 1,
        length: undefined,
        width: undefined,
        height: undefined,
        weigth: undefined,
        store_id: this.userInfo.stores * 1,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.initTrHeight()
      })
    },
    async removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.initTrHeight()
      this.$emit('delParcel')
    },
    copyItem(index, item) {
      const obj = JSON.parse(JSON.stringify(item))
      obj.parcel_id = -1
      this.items.push(obj)
      this.initTrHeight()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-text{
  padding: 0.3rem 1rem;
}
.d-title{
  font-size: 18px;
}
hr{
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}
.form-group{
  margin-bottom: 0;
}
.max-error{
  font-size: 12px;
  color: red;
  display: block;
  text-align: center;
}
@media only screen and (max-width: 990px){
  .del-icon{
    width:30px !important;
    height:30px !important;
  }
  .del-col{
    display: flex;
    justify-content: center;
  }
}
</style>
